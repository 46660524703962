<template>
	<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
		<div class="card">
			<div class="card-body">
				<div class="row">
					<div class="col-md-12">
						<h5>
							<strong>Calendario de gastos operativos</strong>
						</h5>
						<hr class="hrText" data-content="♦" />
					</div>
					<div class="col-md-12">
						<a-tabs defaultActiveKey="1">
							<a-tab-pane tab="Vista calendario" key="1" force-render>
								<FullCalendar :options="calendarOptions" />
							</a-tab-pane>
							<a-tab-pane tab="Detalle" key="2" force-render> detalle </a-tab-pane>
						</a-tabs>
					</div>
				</div>
			</div>
		</div>
	</a-spin>
</template>

<script>
import { mapGetters } from 'vuex'
import '@fullcalendar/core/vdom'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import esLocale from '@fullcalendar/core/locales/es'
import interactionPlugin from '@fullcalendar/interaction'

export default {
	name: 'leadershipOperatingExpensesView',
	components: {
		FullCalendar,
	},
	computed: {
		...mapGetters('leadership', ['spinnerLoader', 'spinnerLoaderLabel']),
	},
	data() {
		return {
			calendarOptions: {
				plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
				initialView: 'dayGridMonth',
				locale: esLocale,
				businessHours: {
					daysOfWeek: [1, 2, 3, 4, 5, 6],
				},
				headerToolbar: {
					start: '',
					center: 'title',
				},
				events: [
					{
						title: 'Meeting',
						start: '2021-11-29T10:30:00',
						end: '2021-11-29T17:30:00',
					},
					{
						title: 'Birthday Party',
						start: '2021-11-30T10:30:00',
						end: '2021-11-30T17:30:00',
						borderColor: '#dedede',
					},
				],
			},
		}
	},
	methods: {
		onAddRecord() {
			//
		},
	},
}
</script>

<style >
.fc-toolbar-title {
	text-transform: uppercase;
}
</style>
